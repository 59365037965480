.search {
  @apply h-full lg:bg-black-100/5 md:relative md:hover:bg-black-100/10;
  @apply min-w-[48px] xsm:aspect-1 xsm:min-w-[auto];

  & :global(> button) {
    @apply h-full w-full focus:outline-none flex flex-col justify-center items-center;
  }

  & :global(.magnifier) {
    @apply w-4 sm:w-5 fill-current stroke-current pointer-events-none;
  }

  & :global(.close) {
    @apply w-3 sm:w-4 fill-current stroke-current pointer-events-none;
  }

  & :global(.on) {
    @apply bg-black-100/15;
    & :global(.close) {
      @apply mb-[4px] sm:mb-0;
    }
  }

  & span {
    @apply text-xs font-bold tracking-[1px] mt-[3px] uppercase;
  }
}

.searchForm {
  @apply absolute bg-white left-0 w-full p-3;
  @apply md:w-85 md:left-auto md:right-0 z-10;

  & :global(.magnifier) {
    @apply absolute left-7 top-7 sm:top-8 text-black-100;
  }

  & :global(input) {
    @apply w-full border border-black-100/20 pl-11 p-3 rounded focus:text-black-100 focus:outline-none appearance-none placeholder-black-100 bg-white;
    @apply sm:py-4;
  }

  & :global(.spinner) {
    @apply absolute w-8 top-3 right-6 opacity-40;
    @apply sm:top-4;
  }
}
